<template>
  <BCard>
    <div class="flex flex-row items-center">
      <span class="text-black font-semibold">Top Pengeluaran Partner</span>
      <img
        v-b-tooltip.hover.top="`Data ini akan terupdate setiap bulan`"
        src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
        alt="Komerce"
        class="ml-[5px] cursor-pointer"
        width="20"
      >
    </div>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        class="max-h-[360px] overflow-auto"
      >
        <BTable
          :items="items"
          :fields="fields"
          class="mt-1"
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          show-empty
        >
          <template #cell(id)="data">
            <span class="text-black font-semibold">{{ data.index + 1 }}</span>
          </template>
          <template #cell(name)="data">
            <div class="flex items-center gap-5">
              <BAvatar :src="data.item.avatar_url" />
              <div>
                <span class="text-black font-semibold">{{ data.item.name }}</span>
                <div class="text-[#828282] text-sm">
                  Total Kartu : {{ data.item.total_card }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(count)="data">
            <span class="text-[#34A770] font-semibold">{{ IDR(data.item.amount) }}</span>
          </template>
        </BTable>
      </div>
    </BOverlay>
  </BCard>
</template>

<script>
import { IDR } from '@/libs/currency'
import { alertError } from '@toast'
import { configColumn } from './config'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: configColumn,
      IDR,
      alertError,
    }
  },
}
</script>
